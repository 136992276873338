<template>
  <div class="callback-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/customer-requests">Customer requests</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex align-center">
        <div class="d-flex align-center">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.person != null && data.person.picture" :src="data.person.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.full_name }}</div>
            <div class="text--text">
              Created at
              {{
                new Date(data.created_at).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat width="140" height="42" class="status-block text-body-2" :class="getColor(data.status)">{{ data.status }}</v-card>
        <v-btn v-if="data.status == 'submitted'" @click="processed" outlined depressed color="primary" class="ms-5" height="46" width="190">
          <v-icon color="primary">mdi-check-bold</v-icon> <span class="font-weight-bold">Mark as Processed</span>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3 width50">
            <div class="font-weight-bold">Phone number</div>
            <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.phone_number }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Email</div>
            <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.email }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Type</div>
            <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate text-capitalize">{{ data.type ? data.type.split('_').join(' ') : '' }}</div>
            </v-card>
          </div>
          <div class="ms-md-3 width50">
            <div v-if="data.type == 'callback'">
              <div class="font-weight-bold mt-md-0 mt-5">Trip dates</div>
              <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
                <div class="text--text text-truncate">
                  {{
                    data.travel_date
                      ? new Date(data.travel_date).toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      : ''
                  }}
                </div>
              </v-card>
              <div class="font-weight-bold mt-5">Participants</div>
              <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
                <div class="text--text text-truncate">{{ data.adults }} adult, {{ data.children }} child, {{ data.infants }} infant</div>
              </v-card>
              <div v-if="data.trip">
                <div class="font-weight-bold mt-5">Trip</div>
                <v-card flat min-height="48" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
                  <div class="primary--text text--darken-1 text-decoration-underline">
                    {{ data.trip.title }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn :to="`/trips/${data.trip.id}/general`" target="_blank" icon small>
                    <v-icon class="f18" color="primary darken-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </v-card>
              </div>
              <div v-if="data.travel_experience">
                <div class="font-weight-bold mt-5">Experience</div>
                <v-card flat min-height="48" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
                  <div class="primary--text text--darken-1 text-decoration-underline">
                    {{ data.travel_experience.title }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn :to="`/experience/${data.travel_experience.id}/general`" target="_blank" icon small>
                    <v-icon class="f18" color="primary darken-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </v-card>
              </div>
            </div>
            <div v-else>
              <div class="font-weight-bold mt-md-0 mt-5">Subject</div>
              <v-card flat height="48" class="gray lighten-4 px-3 d-flex align-center mt-2">
                <div class="text--text text-truncate">{{ data.message_subject }}</div>
              </v-card>
              <div class="font-weight-bold mt-5">Message text</div>
              <v-card flat min-height="148" class="gray lighten-4 px-3 py-3 mt-2">
                <div class="text--text">{{ data.message_text }}</div>
              </v-card>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="font-weight-bold mb-2">Additional notes</div>
          <v-textarea
            :error-messages="notesErrors"
            v-model="data.notes"
            placeholder="Add notes"
            outlined
            dense
            required
            auto-grow
            rows="5"
            row-height="24"
          ></v-textarea>
        </div>
      </div>
      <div>
        <v-divider></v-divider>
        <div class="text-end py-3 px-sm-10 px-3">
          <v-btn @click="notes" depressed class="rounded primary text-body-2" width="106" height="30">Save</v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCallbackItem');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Processed was successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    getColor(s) {
      if (s == 'submitted') {
        return 'success--text success lighten-5';
      } else if (s == 'expired') {
        return 'error--text error lighten-5';
      } else if (s == 'processed') {
        return 'primary--text primary lighten-3';
      } else {
        return 'gray--text gray lighten-3';
      }
    },
    async processed() {
      this.error = [];
      await this.$store
        .dispatch('processedCallbackItem')
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
    async notes() {
      this.error = [];
      const data = new Object();
      data.notes = this.data.notes || '';
      await this.$store
        .dispatch('notesCallbackItem', data)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.callback;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    notesErrors() {
      const errors = [];
      this.error.find((item) => item == 'notes__required') && errors.push('Please enter your notes');
      this.error.find((item) => item == 'notes__invalid') && errors.push('Provided notes is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setCallbackItem');
  },
};
</script>

<style lang="scss">
.callback-item {
  .status-block {
    font-weight: 700;
    line-height: 42px !important;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
